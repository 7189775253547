import React from 'react';
import { SEO } from '../components/SEO';

const PresentationsPage = () => (
  <>
    <SEO title="Presentations" />
  </>
);

export default PresentationsPage;
